import React from 'react';
import { Modal, ButtonBar, Button, Heading } from '@tg/core/components';
import { useTranslation } from 'react-i18next';

interface LeaveDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  holidayDays: string;
  carryoverLeaves: string;
  year: number;
  activePeriod: number;
}

const LeaveDetailsModal: React.FC<LeaveDetailsModalProps> = ({
  isOpen,
  onClose,
  holidayDays,
  carryoverLeaves,
  year,
  activePeriod,
}) => {
  const { t } = useTranslation(['time_off']);

  return (
    <Modal isOpen={isOpen} onClose={onClose} width='xs'>
      <div className='flex px-6'>
        <div className='mr-4'>
          <Heading level='h4' zeropad>
            Carryover and Holiday Details
          </Heading>
        </div>
      </div>

      <Modal.Content>
        {activePeriod ? (
          <div>
            <p>
              <b>Holiday Leave:</b>{' '}
              {t('time_off:employee.summary_officer', {
                currentYear: activePeriod || year,
                entitlement: holidayDays?.totalDays ?? 0,
                remaining:
                  (holidayDays?.totalDays ?? 0) - (holidayDays?.used ?? 0) < 0
                    ? 0
                    : (holidayDays?.totalDays ?? 0) - (holidayDays?.used ?? 0),
                count: holidayDays?.totalDays ?? 0, // Ensures correct pluralization
              })}
            </p>
            <p>
              <b>Carryover Leave: </b>
              {t('time_off:employee.summary_carry_over_officer', {
                currentYear: activePeriod || year,
                entitlement: carryoverLeaves?.totalDays ?? 0,
                remaining:
                  (carryoverLeaves?.totalDays ?? 0) -
                    (carryoverLeaves?.used ?? 0) <
                  0
                    ? 0
                    : (carryoverLeaves?.totalDays ?? 0) -
                      (carryoverLeaves?.used ?? 0),
                count: carryoverLeaves?.totalDays ?? 0, // Ensures correct pluralization
              })}
            </p>
          </div>
        ) : (
          <div style={{ fontWeight: 500 }}>No Active Planner Year Found</div>
        )}
      </Modal.Content>

      <ButtonBar>
        <Button type='button' color='secondary' onClick={onClose}>
          {t('forms:buttons.modal.close')}
        </Button>
      </ButtonBar>
    </Modal>
  );
};

export default LeaveDetailsModal;
